"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LockService = void 0;
const applicationDataService_1 = require("./applicationDataService");
const bff_service_1 = require("./bff-service");
const constants_1 = require("./constants");
class LockService {
    static get Instance() {
        if (!LockService._instance) {
            LockService._instance = new LockService();
        }
        return LockService._instance;
    }
    /**
     * This method will enable to acquire lock for array of resource id provided
     * It will either return successful response with lockid or error response
     * @param {string[]} resourceIds - Array of resource ids for which lock needs to be acquired
     */
    acquireLock(resourceIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + constants_1.ACQUIRE_LOCK;
            const payload = {
                resourceIds: resourceIds,
                sessionId: `${applicationDataService_1.ApplicationDataService.Instance.getSessionId()}_${Date.now()}`
            };
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "post",
                headers: applicationDataService_1.ApplicationDataService.Instance.getRequestHeaders(),
                body: JSON.stringify(payload)
            });
            res = yield this._handleErrors(res);
            return yield res.json();
        });
    }
    /**
     * This method will enable to release lock for the lock id provided
     * @param {string} lockId - Lock id for which lock needs to be released
     */
    releaseLock(lockId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = applicationDataService_1.ApplicationDataService.Instance.getApiURL() + (0, constants_1.RELEASE_LOCK)(lockId);
            let res = yield (0, bff_service_1.fetchThroughBff)(url, {
                method: "post",
                headers: applicationDataService_1.ApplicationDataService.Instance.getRequestHeaders()
            });
            res = yield this._handleErrors(res);
            return yield res.json();
        });
    }
    /**
     * This method will enable to release and acquire lock for the resource id provided
     * @param {string} resourceId - resource id for which lock needs to be overriden
     */
    overrideLock(resourceId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.releaseLock(resourceId);
                return yield this.acquireLock([resourceId]);
            }
            catch (e) { // error thrown from releaseLock or acquireLock
                return e;
            }
        });
    }
    /**
   * Handles errors for API calls.
   * @param {Response} response - The response object from the API call.
   * @returns {Promise<any>} - Returns the response object if it is ok.
   * @throws {Error} - Throws an error object if the response is not ok.
   */
    _handleErrors(response) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!response.ok) {
                let errorMsg = '';
                try {
                    const errObj = yield response.json();
                    console.error('Error during API calls', errObj);
                    errorMsg = (errObj === null || errObj === void 0 ? void 0 : errObj.message) || (errObj === null || errObj === void 0 ? void 0 : errObj.title) || '';
                }
                catch (e) {
                    console.log('handleErrors: Error during parsing JSON');
                }
                const error = {
                    name: response.statusText,
                    message: errorMsg,
                    stack: (_a = response.status) === null || _a === void 0 ? void 0 : _a.toString()
                };
                throw error;
            }
            return response;
        });
    }
}
exports.LockService = LockService;
